<template>
  <div class="payment-activity-wrapper">
    <payment-request v-if="selectedPage === 'PR'" @handlePage="selectedPage = $event"></payment-request>
    <transaction-list @handlePage="selectedPage = $event" v-else></transaction-list>
  </div>
</template>

<script>
import PaymentRequest from "./paymentRequest";
import TransactionList from "./transactionList";

export default {
  components: {
    TransactionList,
    PaymentRequest,
  },
  data() {
    return {
      selectedPage: "PR",
    };
  },
};
</script>