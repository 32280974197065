<template>
  <div class="payment-activity-container">
    <vs-row class="items-start">
      <vs-col vs-align="center" vs-justify="center" vs-type="flex" vs-w="12">
        <vx-card class="custom-vx-card" style="background: #f8f8f8">
          <tabs :tabs-list="tabs" default-select="PR" @handleEvent="selectedPage = $event"></tabs>
          <vs-table :data="paymentrequests" class="pay-activity-table table-small-text stripes">
            <template slot="header">
            </template>

            <template slot="thead">
              <vs-th width="12%">
                <div class="boxBody">
                  <div class="combo-box" @click="changeFilter('paymentRequestId')">
                    <label class="m-0 p-0 text-base font-medium">Request ID</label>
                    <div class="icon-combo" v-if="!filtersHidden">
                      <vs-icon :class="searchParam.sortBy === 'paymentRequestId' && searchParam.sortDir === 'asc' ? 'dark' : ''" icon="arrow_drop_up"/>
                      <vs-icon :class="searchParam.sortBy === 'paymentRequestId' && searchParam.sortDir === 'desc' ? 'dark' : ''" icon="arrow_drop_down" />
                    </div>
                  </div>
                  <span @click="resetInput($event, 'paymentRequestId')" v-if="searchParam.paymentRequestId" class="iconClose">X</span>
                  <vs-input
                    v-if="!filtersHidden"
                    class="w-auto"
                    v-model="searchParam.paymentRequestId"
                    v-bind:class="{
                      isFocus: searchParam.paymentRequestId,
                      textBlue: searchParam.paymentRequestId,
                    }"
                    @change="onChangeInput($event)"
                  />
                </div>
              </vs-th>

              <vs-th width="13%">
                <div class="boxBody">
                  <div class="combo-box"@click="changeFilter('reference')">
                    <label class="m-0 p-0 text-base font-medium">Merchant ref</label>
                    <div class="icon-combo" v-if="!filtersHidden">
                      <vs-icon :class="searchParam.sortBy === 'reference' && searchParam.sortDir === 'asc' ? 'dark' : ''" icon="arrow_drop_up"/>
                      <vs-icon :class="searchParam.sortBy === 'reference' && searchParam.sortDir === 'desc' ? 'dark' : ''" icon="arrow_drop_down" />
                    </div>
                  </div>
                  <span @click="resetInput($event, 'reference')" v-if="searchParam.reference" class="iconClose" >X</span>
                  <vs-input v-if="!filtersHidden" class="w-auto" v-model="searchParam.reference" v-bind:class="{ isFocus: searchParam.reference, textBlue: searchParam.reference }" @change="onChangeInput($event)" />
                </div>
              </vs-th>

              <vs-th width="12%">
                <div class="boxBody">
                  <div class="combo-box" @click="changeFilter('merchantId')">
                    <label class="m-0 p-0 text-base font-medium">Merchant ID</label>
                    <div class="icon-combo" v-if="!filtersHidden">
                      <vs-icon :class="searchParam.sortBy === 'merchantId' && searchParam.sortDir === 'asc' ? 'dark' : ''" icon="arrow_drop_up"/>
                      <vs-icon :class="searchParam.sortBy === 'merchantId' && searchParam.sortDir === 'desc' ? 'dark' : ''" icon="arrow_drop_down"/>
                    </div>
                  </div>
                  <span @click="resetInput($event, 'merchantId')" v-if="searchParam.merchantId" class="iconClose">X</span>
                  <vs-input v-if="!filtersHidden" class="w-auto" v-model="searchParam.merchantId" v-bind:class="{ isFocus: searchParam.merchantId, textBlue: searchParam.merchantId }" @change="onChangeInput($event)" />
                </div>
              </vs-th>
              <vs-th width="15%">
                <div class="boxBody">
                  <div class="combo-box" @click="changeFilter('merchantName')">
                    <label class="m-0 p-0 text-base font-medium">Merchant name</label>
                    <div class="icon-combo" v-if="!filtersHidden">
                      <vs-icon :class="searchParam.sortBy === 'merchantName' && searchParam.sortDir === 'asc' ? 'dark' : ''" icon="arrow_drop_up"/>
                      <vs-icon :class="searchParam.sortBy === 'merchantName' && searchParam.sortDir === 'desc' ? 'dark' : ''" icon="arrow_drop_down"/>
                    </div>
                  </div>
                  <span @click="resetInput($event, 'merchantName')" v-if="searchParam.merchantName" class="iconClose">X</span>
                  <vs-input v-if="!filtersHidden" class="w-auto" v-model="searchParam.merchantName" v-bind:class="{ isFocus: searchParam.merchantName, textBlue: searchParam.merchantName }" vs-input @change="onChangeInput($event)" />
                </div>
              </vs-th>

              <vs-th width="15%">
                <div>
                  <div class="combo-box" @click="changeFilter('date')">
                    <label>Date</label>
                    <div class="icon-combo" v-if="!filtersHidden">
                      <vs-icon :class="searchParam.sortBy === 'date' && searchParam.sortDir === 'asc' ? 'dark' : ''" icon="arrow_drop_up"/>
                      <vs-icon :class="searchParam.sortBy === 'date' && searchParam.sortDir === 'desc' ? 'dark' : ''" icon="arrow_drop_down"/>
                    </div>
                  </div>
                  <date-picker
                    v-bind:class="{ active: searchParam.date }"
                    @change="onChangeInput()"
                    valueType="format"
                    v-if="!filtersHidden"
                    v-model="searchParam.date"
                    lang="en"
                    type="date"
                    placeholder="DD/MM/YYYY"
                    class="pay-date"
                    format="DD/MM/YYYY"
                  ></date-picker>
                </div>
              </vs-th>
              <vs-th width="10%">
                <div class="boxBody">
                  <div class="combo-box" @click="changeFilter('product')">
                    <label class="m-0 p-0 text-base font-medium">Product</label>
                    <div class="icon-combo" v-if="!filtersHidden">
                      <vs-icon :class="searchParam.sortBy === 'product' && searchParam.sortDir === 'asc' ? 'dark' : ''" icon="arrow_drop_up"/>
                      <vs-icon :class="searchParam.sortBy === 'product' && searchParam.sortDir === 'desc' ? 'dark' : ''" icon="arrow_drop_down"/>
                    </div>
                  </div>
                  <vs-select v-if="!filtersHidden" v-model="searchParam.product" :multiple="true" width="200px" v-bind:class="{ isFocus: searchParam.product, textBlue: searchParam.product }">
                    <vs-select-item v-for="(item, index) in products" :key="index" :text="item.productName" :value="item.productName" @click.native="checkProduct(item.productName)" />
                  </vs-select>
                </div>
              </vs-th>

              <vs-th width="7%">
                <div class="boxBody">
                  <div class="combo-box" @click="changeFilter('amount')">
                    <label class="m-0 p-0 text-base font-medium">Amount</label>
                    <div class="icon-combo" v-if="!filtersHidden">
                      <vs-icon :class="searchParam.sortBy === 'amount' && searchParam.sortDir === 'asc' ? 'dark' : ''" icon="arrow_drop_up"/>
                      <vs-icon :class="searchParam.sortBy === 'amount' && searchParam.sortDir === 'desc' ? 'dark' : ''" icon="arrow_drop_down"/>
                    </div>
                  </div>
                  <span @click="resetInput($event, 'amount')" v-if="searchParam.amount" class="iconClose">X</span>
                  <vs-input v-if="!filtersHidden" class="w-auto" v-model="searchParam.amount" v-bind:class="{ isFocus: searchParam.amount, textBlue: searchParam.amount }" @change="onChangeInput($event)" />
                </div>
              </vs-th>
              <vs-th width="10%">
                <div>
                  <div class="combo-box" @click="changeFilter('statusType')">
                    <label class="m-0 p-0 text-base font-medium">Status</label>
                    <div class="icon-combo" v-if="!filtersHidden">
                      <vs-icon :class="searchParam.sortBy === 'statusType' && searchParam.sortDir === 'asc' ? 'dark' : ''" icon="arrow_drop_up"/>
                      <vs-icon :class="searchParam.sortBy === 'statusType' && searchParam.sortDir === 'desc' ? 'dark' : ''" icon="arrow_drop_down"/>
                    </div>
                  </div>
                  <vs-select
                    v-if="!filtersHidden"
                    v-model="searchParam.statusType"
                    :multiple="true"
                    width="150px"
                    v-bind:class="{
                      isFocus: searchParam.statusType,
                      textBlue: searchParam.statusType,
                    }"
                    @change="onChangeInput($event)"
                    @click="changeStatus($event)"
                  >
                    <vs-select-item v-for="(item, index) in statusTypes" :key="index" :text="item.text" :value="item.value" @click.native="checkValue(item.value)" />
                  </vs-select>
                </div>
              </vs-th>
              <vs-th class="filter-cross" :class="{ toggle: !filtersHidden }">
                <filter-icon size="1.5x" :fill="filterIconColor.fill" :stroke="filterIconColor.stroke" @click="toggleFilter"></filter-icon>
              </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr v-for="(tr, indextr) in data" :key="indextr">
                <vs-td :data="tr.paymentRequestId">
                  {{ tr.paymentRequestId }}
                </vs-td>

                <vs-td :data="tr.reference">
                  {{ tr.reference }}
                </vs-td>

                <vs-td :data="tr.merchantDetails.merchantId">
                  {{ tr.merchantDetails.merchantId }}
                </vs-td>

                <vs-td :data="tr.merchantDetails.companyName">
                  {{ tr.merchantDetails.companyName }}
                </vs-td>

                <vs-td :data="tr.createdAt">
                  {{ moment(tr.createdAt) }}
                </vs-td>

                <vs-td :data="tr.paymentRequestId">
                  {{ getPlanName(tr) }}
                </vs-td>

                <vs-td :data="tr.amount">
                  {{ formatCurrency(tr.amount.$numberDecimal || tr.amount) }}
                </vs-td>

                <vs-td :data="tr.paymentStatus">
                  <span :class="changeStatusColor(tr.paymentStatus)">{{ tr.paymentStatus }}</span>
                </vs-td>
                <vs-td :data="tr" class="text-center">
                  <router-link :to="{ name: 'staff-payment-requests-detail', params: { id: tr._id } }" class="underline cursor-pointer">
                    View
                  </router-link>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <div>
            <vs-row class="vs-row justify-between my-5 items-center">
              <div class="records-per-page flex items-center">
                <label class="w-full mb-0">Records per page</label>
                <vs-select v-model="searchParam.limit" class="per-pg">
                  <vs-select-item v-for="(item, index) in limitValue" :key="index" :text="item.text" :value="item.value" />
                </vs-select>
              </div>

              <div class="ds-wrapper">
                <vs-row>
                  <vs-button v-if="searchParam.pageNumber > 1" @click="handlePageChange('DECREMENT')" color="primary" variant="icon-button" icon="chevron_left"></vs-button>
                  <vs-button v-if="paymentrequests.length > searchParam.limit" @click="handlePageChange('INCREMENT')" color="primary" variant="icon-button" icon="chevron_right" class="ml-1"></vs-button>
                </vs-row>
              </div>
            </vs-row>
          </div>
        </vx-card>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import vPegination from "@/views/components/pagination.vue";
import { mapActions } from "vuex";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import { FilterIcon } from "vue-feather-icons";
import TableFilter from "./paymentFilter";
import Tabs from "@/views/components/Tabs";
import _ from "lodash";

export default {
  components: {
    DatePicker,
    vPegination,
    FilterIcon,
    TableFilter,
    Tabs,
  },
  name: "PaymentRequest",
  data() {
    return {
      filtersHidden: true,
      tabs: [
        { key: "PR", text: "Payment Requests" },
        { key: "TX", text: "Transactions" },
      ],
      selectedPage: "PR",
      searchParam: {
        pageNumber: 1,
        merchantId: "",
        merchantName: "",
        paymentRequestId: "",
        totalData: 0,
        limit: 50,
        date: "",
        reference: "",
        customer: "",
        requestType: "all",
        amount: "",
        product: [],
        statusType: [""],
        pageList: ["all"],
        sortBy: "createdAt",
        sortDir: "desc",
      },
      paymentrequests: [],
      products: [
        {
          productName: "All",
        },
        {
          productName: "Pay now",
        },
        {
          productName: "Pay later",
        },
      ],
      limit: 50,
      limitValue: [
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      requestTypes: [
        { text: "All", value: "all" },
        { text: "Pay Now", value: "pay-now" },
        { text: "Pay Later", value: "pay-later" },
      ],
      statusTypes: [
        { text: "Show all", value: "all" },
        { text: "Pending", value: "Pending" },
        { text: "Active", value: "Active" },
        { text: "Complete", value: "Completed" },
        { text: "Expired", value: "Expired" },
        { text: "Cancelled", value: "Cancelled" },
        { text: "Info-required", value: "Info-required" },
        { text: "Extended", value: "Extended" },
        { text: "Submitted", value: "Submitted" },
        { text: "Declined", value: "Declined" },
      ],
    };
  },
  methods: {
    ...mapActions("paymentRequest", ["fetchPaymentRequestListWithFilter"]),
    ...mapActions("paymentPlans", ["fetchProducts"]),

    moment(date) {
      return moment(date).format("DD-MMM-YYYY");
    },

    handlePageChange(type) {
      if (this.searchParam.pageNumber == 1 && type == "DECREMENT") {
        return;
      } else if (type == "INCREMENT") {
        this.searchParam.pageNumber = parseInt(this.searchParam.pageNumber) + 1;
      } else {
        this.searchParam.pageNumber  = parseInt(this.searchParam.pageNumber) - 1;
      }
    },

    getPlanName(val) {
      if (val.requestOptions.includes("pay-later") && val.requestOptions.includes("pay-now")) {
        if (val.selectedPaymentPlan === null || !val.selectedPaymentPlan) {
          if (val.paymentStatus === "Completed") {
            return "Pay now";
          }

          return "Pay now / Pay later";
        } else if (val.selectedPaymentPlan) {
          return val.selectedPaymentPlan.currentPlan ? val.selectedPaymentPlan.currentPlan.productName : "";
        }
      } else {
        return val.payLaterPlan && val.payLaterPlan.length > 0 ? val.payLaterPlan[0].productName : "Pay now";
      }
    },

    async getAllPayLaterProducts() {
      await this.fetchProducts().then((res) => {
        this.products.push(...res.data.data);
      });
    },
    toggleFilter() {
      this.filtersHidden = !this.filtersHidden;
      if (this.filtersHidden === true) {
        this.searchParam = {
          pageNumber: 1,
          totalData: 0,
          limit: 50,
          date: "",
          reference: "",
          customer: "",
          requestType: "all",
          amount: "",
          merchantId: "",
          merchantName: "",
          paymentRequestId: "",
          product: "",
          statusType: [""],
          pageList: ["all"],
          sortBy: "createdAt",
          sortDir: "desc",
        };
      }
    },

    moneyFormat(data) {
      return `$ ${parseFloat(data)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
    },
    momentTime(date) {
      return moment(date).format("h:mm:ss");
    },
    async getPaymentRequestList() {
      this.$vs.loading();
      const data = {
        searchParam: this.searchParam,
      };
      await this.fetchPaymentRequestListWithFilter(data.searchParam).then((result) => {
        this.paymentrequests = result.data.data;
        this.$vs.loading.close();
      }).catch((ex) => {
        this.$vs.loading.close();
      });
    },
    showPaymentPopup(pageId) {
      this.selectedPageId = pageId;
      this.paymentRequestPopup = true;
    },
    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },
    viewDetail(data) {
      this.$router.push({
        name: "staff-payment-requests-detail",
        params: { id: data._id },
      });
    },
    changeFilter(value) {
      this.searchParam.sortBy = value;
      this.searchParam.sortDir = this.searchParam.sortDir === "desc" ? "asc" : "desc";
    },
    changeStatus(event) {
      let showArray = this.statusType;
    },
    onChangeInput(val) {},
    checkValue(val) {
      // handle uncheck when all is selected
      if (this.searchParam.statusType.includes("all") && val !== "all") {
        this.searchParam.statusType.splice(
          this.searchParam.statusType.indexOf("all"),
          1
        );
      }

      // unselect show all
      if (this.searchParam.statusType.includes("all") && val == "all") {
        this.searchParam.statusType = [];
      }

      //select show all
      if (!this.searchParam.statusType.includes("all") && val == "all") {
        this.selectAll();
      }
    },
    checkProduct(val) {
      // handle uncheck when all is selected
      if (this.searchParam.product.includes("All") && val !== "All") {
        this.searchParam.product.splice(
          this.searchParam.product.indexOf("All"),
          1
        );
      }

      // unselect show all
      if (val == "All") {
        if (this.searchParam.product.includes("All")) {
          this.selectAllProduct();
        } else {
          this.searchParam.product = [];
        }
      }
    },
    changeStatusColor(status) {
      let bgClass = "";
      if (["Cancelled", "Declined", "Expired"].includes(status)) {
        bgClass = "badge danger";
      } else if (status == "Refunded") {
        bgClass = "badge warning";
      } else if (status == "Completed") {
        bgClass = "badge success";
      } else if (["Pending"].includes(status)) {
        bgClass = "badge pending";
      } else {
        bgClass = "badge primary";
      }
      return bgClass;
    },

    selectAll() {
      const statuses = this.statusTypes.map((el) => el.value);
      this.searchParam.statusType = statuses;
    },

    selectAllProduct() {
      const statuses = this.products.map((el) => el.productName);
      this.searchParam.product = statuses;
    },
    resetInput(event, id) {
      if (id == "date") {
        this.searchParam.date = "";
      } else if (id == "amount") {
        this.searchParam.amount = "";
      } else if (id == "customer") {
        this.searchParam.customer = "";
      } else if (id == "merchantName") {
        this.searchParam.merchantName = "";
      } else if (id == "merchantId") {
        this.searchParam.merchantId = "";
      } else if (id == "paymentRequestId") {
        this.searchParam.paymentRequestId = "";
      } else if (id == "product") {
        this.searchParam.product = "";
      } else if (id == "reference") {
        this.searchParam.reference = "";
      }
    },
  },
  mounted() {
    this.getAllPayLaterProducts();
    this.getPaymentRequestList();
  },
  watch: {
    selectedPage(val) {
      this.$emit("handlePage", val);
    },
    "searchParam.pageNumber"(page) {
      this.getPaymentRequestList();
    },
    "searchParam.limit"(val) {
      this.getPaymentRequestList();
      this.saveListSetting(val);
    },
    "searchParam.date"(val) {
      this.getPaymentRequestList();
    },
    "searchParam.reference":_.debounce(function (val){
      this.getPaymentRequestList();
    }, 600),

    "searchParam.customer"(val) {
      this.getPaymentRequestList();
    },

    "searchParam.requestType"(val) {
      this.getPaymentRequestList();
    },

    "searchParam.paymentRequestId": _.debounce(function (val) {
      this.getPaymentRequestList();
    }, 600),

    "searchParam.merchantId": _.debounce(function (val) {
      this.getPaymentRequestList();
    }, 600),

    "searchParam.merchantName": _.debounce(function (val) {
      this.getPaymentRequestList();
    }, 600),

    "searchParam.amount": _.debounce(function (val) {
      this.getPaymentRequestList();
    }, 600),
    "searchParam.statusType"(val) {
      this.getPaymentRequestList();
    },
    "searchParam.product"(val) {
      this.getPaymentRequestList();
    },
    "searchParam.pageList"(val) {
      this.getPaymentRequestList();
    },
    "searchParam.sortDir"(val) {
      this.getPaymentRequestList();
    },
    "searchParam.sortBy"(val) {
      this.getPaymentRequestList();
    },
  },
  computed: {
    filterIconColor() {
      if (!this.filtersHidden) {
        return { fill: "#0f67f4", stroke: "#0f67f4" };
      }

      return { fill: "white", stroke: "#828282" };
    },
    user() {
      return this.$store.state.AppActiveUser;
    },
    partnerId() {
      return ["admin", "superadmin"].includes(this.user.userType.toLowerCase()) ? this.user._id : this.user.partnerId;
    },
    totalPage: function () {
      return Math.ceil(this.searchParam.totalData / this.searchParam.limit);
    },
  },
};
</script>