<template>
  <div class="payment-activity-container">
    <vs-row class="items-start">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <vx-card class="custom-vx-card">
          <tabs :tabs-list="tabs" default-select="TX" @handleEvent="selectedPage = $event"></tabs>
          <vs-table :data="transactions" class="pay-activity-table stripes table-small-text" stripe="true">
            <template slot="header">
            </template>
            <template slot="thead">
              <vs-th>
                <div class="boxBody">
                  <div class="combo-box" @click="changeFilter('tra')">
                    <label class="m-0 p-0 text-base font-medium">Transaction ID</label>
                    <div class="icon-combo" v-if="!filtersHidden">
                      <vs-icon :class="searcParam.sortBy === 'transaction' && searcParam.sortDir === 'asc' ? 'dark' : ''" icon="arrow_drop_up" />
                      <vs-icon :class="searcParam.sortBy === 'transaction' && searcParam.sortDir === 'desc' ? 'dark' : ''" icon="arrow_drop_down" />
                    </div>
                  </div>
                  <span @click="resetInput($event, 'transaction')" v-if="searcParam.transaction" class="iconClose">X</span>
                  <vs-input v-if="!filtersHidden" class="w-auto" v-model="searcParam.transaction" v-bind:class="{ isFocus: searcParam.transaction, textBlue: searcParam.transaction }" vs-input @change="onChangeInput($event)" />
                </div>
              </vs-th>
              <vs-th width="15%">
                <div class="boxBody">
                  <div class="combo-box" @click="changeFilter('paymentRequestId')">
                    <label class="m-0 p-0 text-base font-medium">Request ID</label>
                    <div class="icon-combo" v-if="!filtersHidden">
                      <vs-icon :class="searcParam.sortBy === 'paymentRequestId' && searcParam.sortDir === 'asc' ? 'dark' : ''" icon="arrow_drop_up" />
                      <vs-icon :class="searcParam.sortBy === 'paymentRequestId' && searcParam.sortDir === 'desc' ? 'dark' : ''" icon="arrow_drop_down" />
                    </div>
                  </div>
                  <span @click="resetInput($event, 'paymentRequestId')" v-if="searcParam.paymentRequestId" class="iconClose">X</span>
                  <vs-input
                    v-if="!filtersHidden"
                    class="w-auto"
                    v-model="searcParam.paymentRequestId"
                    v-bind:class="{
                      isFocus: searcParam.paymentRequestId,
                      textBlue: searcParam.paymentRequestId,
                    }"
                    @change="onChangeInput($event)"
                  />
                </div>
              </vs-th>

              <vs-th width="15%">
                <div class="boxBody">
                  <div class="combo-box" @click="changeFilter('merchantId')">
                    <label class="m-0 p-0 text-base font-medium">Merchant ID</label>
                    <div class="icon-combo" v-if="!filtersHidden">
                      <vs-icon :class="searcParam.sortBy === 'merchantId' && searcParam.sortDir === 'asc' ? 'dark' : ''" icon="arrow_drop_up" />
                      <vs-icon :class="searcParam.sortBy === 'merchantId' && searcParam.sortDir === 'desc' ? 'dark' : ''" icon="arrow_drop_down" />
                    </div>
                  </div>
                  <span @click="resetInput($event, 'merchantId')" v-if="searcParam.merchantId" class="iconClose">X</span>
                  <vs-input
                    v-if="!filtersHidden"
                    class="w-auto"
                    v-model="searcParam.merchantId"
                    v-bind:class="{
                      isFocus: searcParam.merchantId,
                      textBlue: searcParam.merchantId,
                    }"
                    @change="onChangeInput($event)"
                  />
                </div>
              </vs-th>

              <vs-th width="15%">
                <div class="boxBody">
                  <div class="combo-box" @click="changeFilter('amount')">
                    <label>Amount</label>
                    <div class="icon-combo" v-if="!filtersHidden">
                      <vs-icon icon="arrow_drop_up" :class="searcParam.sortBy === 'amount' && searcParam.sortDir === 'asc' ? 'dark' : ''" />
                      <vs-icon icon="arrow_drop_down" :class="searcParam.sortBy === 'amount' && searcParam.sortDir === 'desc' ? 'dark' : ''" />
                    </div>
                  </div>
                  <span @click="resetInput($event, 'amount')" v-if="searcParam.amount" class="iconClose">X</span>
                  <vs-input
                    v-if="!filtersHidden"
                    class="w-auto"
                    v-model="searcParam.amount"
                    v-bind:class="{
                      active: searcParam.amount,
                      textBlue: searcParam.amount,
                    }"
                    @change="onChangeInput()"
                  />
                </div>
              </vs-th>

              <vs-th width="15%">
                <div>
                  <div class="combo-box" @click="changeFilter('date')">
                    <label>Date</label>
                    <div class="icon-combo" v-if="!filtersHidden">
                      <vs-icon icon="arrow_drop_up" :class="searcParam.sortBy === 'date' && searcParam.sortDir === 'asc' ? 'dark' : ''" />
                      <vs-icon icon="arrow_drop_down" :class="searcParam.sortBy === 'date' && searcParam.sortDir === 'desc' ? 'dark' : ''" />
                    </div>
                  </div>
                  <date-picker
                    v-bind:class="{ active: searcParam.date }"
                    @change="onChangeInput()"
                    valueType="format"
                    v-if="!filtersHidden"
                    v-model="searcParam.date"
                    lang="en"
                    type="date"
                    placeholder="DD/MM/YYYY"
                    class="pay-date"
                    format="DD/MM/YYYY"
                  ></date-picker>
                </div>
              </vs-th>

              <vs-th width="10%">
                <div>
                  <div class="combo-box" @click="changeFilter('statusType')">
                    <label>Status</label>
                    <div class="icon-combo" v-if="!filtersHidden">
                      <vs-icon icon="arrow_drop_up" :class="searcParam.sortBy === 'statusType' && searcParam.sortDir === 'asc' ? 'dark' : ''" />
                      <vs-icon icon="arrow_drop_down" :class="searcParam.sortBy === 'statusType' && searcParam.sortDir === 'desc' ? 'dark' : ''" />
                    </div>
                  </div>
                  <vs-select v-if="!filtersHidden" @click="changeStatus($event)" @change="onChangeInput()" v-model="searcParam.statusType" :multiple="true" width="150px">
                    <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in statusTypes" @click.native="checkValue(item.value)" />
                  </vs-select>
                </div>
              </vs-th>
              <vs-th class="filter-cross text-center" :class="{ toggle: !filtersHidden }">
                <filter-icon size="1.5x" class="custom-class" :fill="filterIconColor.fill" :stroke="filterIconColor.stroke" @click="toggleFilter"></filter-icon>
              </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="tr.transactionID">{{ tr.transactionID }}</vs-td>
                <vs-td :data="tr.paymentRequestId">{{ tr.paymentRequest.paymentRequestId }}</vs-td>
                <vs-td :data="tr.partnerId">{{ tr.partner.merchantId }}</vs-td>
                <vs-td :data="tr.amount">{{ formatCurrency(tr.amount) }}</vs-td>
                <vs-td :data="tr.createdAt">{{ moment(tr.createdAt) }}</vs-td>
                <vs-td :data="tr.paymentStatus">
                  <span :class="changeStatusColor(tr.paymentStatus)">
                    {{ tr.paymentStatus === "Refunded" ? ((tr.refundedByUser.length > 0) ? "Refunded By " + tr.refundedByUser[0].fullName : "Refunded") : tr.paymentStatus }}
                  </span>
                </vs-td>
                <vs-td :data="tr" class="text-center">
                  <a @click="viewDetail(tr)" class="underline cursor-pointer">View</a>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <div>
            <vs-row class="vs-row justify-between px-5 my-10 items-center">
              <div class="records-per-page flex items-center">
                <label class="w-full mb-0">Records per page</label>
                <vs-select v-model="searcParam.limit" class="per-pg">
                  <vs-select-item v-for="(item, index) in limitValue" :key="index" :text="item.text" :value="item.value" />
                </vs-select>
              </div>
              <div>
                <vs-row>
                  <vPegination :total="totalPage" v-model="searcParam.pageNumber" :page-count="totalPage"></vPegination>
                </vs-row>
              </div>
            </vs-row>
          </div>
        </vx-card>
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import moment from "moment";
import { FilterIcon } from "vue-feather-icons";
import DatePicker from "vue2-datepicker";
import vPegination from "@/views/components/pagination";
import Tabs from "@/views/components/Tabs";
import _ from "lodash";
export default {
  components: {
    Tabs,
    DatePicker,
    vPegination,
    FilterIcon,
  },
  data() {
    return {
      filtersHidden: true,
      tabs: [
        { key: "PR", text: "Payment Requests" },
        { key: "TX", text: "Transactions" },
      ],
      selectedPage: "TX",
      searcParam: {
        pageNumber: 1,
        totalData: 0,
        limit: 50,
        merchantId: "",
        paymentRequestId: "",
        date: "",
        reference: "",
        customer: "",
        transaction: "",
        amount: "",
        statusType: [""],
        pageList: ["all"],
        sortBy: "createdAt",
        sortDir: "desc",
      },
      pageNumber: 1,
      totalData: 0,
      limit: 50,
      transactions: [],
      limitValue: [
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      statusTypes: [
        { text: "Show all", value: "all" },
        { text: "Pending", value: "Pending" },
        { text: "Completed", value: "Completed" },
        { text: "Expired", value: "Expired" },
        { text: "Cancelled", value: "Cancelled" },
        { text: "Success", value: "Success" },
        { text: "Failed", value: "Failed" },
        { text: "Schedule registered", value: "Schedule registered" },
        { text: "Schedule failed", value: "Schedule failed" },
        { text: "Schedule cancelled", value: "Schedule cancelled" },
      ],
    };
  },
  methods: {
    ...mapActions("transaction", ["fetchTransactionListWithFilter"]),

    async getListSetting() {
      const payload = {
        name: this.$route.name,
        partnerId: this.partnerId,
      };
      await this.$store.dispatch("listSetting/fetchListSettingByPage", payload).then((res) => {
        this.limit = parseInt(res.data.data.limit) || this.searcParam.limit;
      }).catch((ex) => {});
    },
    selectAll() {
      const statuses = this.statusTypes.map((el) => el.value);
      this.searcParam.statusType = statuses;
    },
    checkValue(val) {
      // handle uncheck when all is selected
      if (this.searcParam.statusType.includes("all") && val !== "all") {
        this.searcParam.statusType.splice(this.searcParam.statusType.indexOf("all"), 1);
      }

      // unselect show all
      if (this.searcParam.statusType.includes("all") && val == "all") {
        this.searcParam.statusType = [];
      }

      //select show all
      if (!this.searcParam.statusType.includes("all") && val == "all") {
        this.selectAll();
      }
    },
    async saveListSetting(limit) {
      const payload = {
        name: this.$route.name,
        limit,
        partnerId: this.partnerId,
      };
      await this.$store.dispatch("listSetting/updatelistSetting", payload).then((res) => {}).catch((ex) => {});
    },
    moment(date) {
      return moment(date).format("DD-MMM-YYYY");
    },
    momentTime(date) {
      return moment(date).format("h:mm:ss");
    },
    async getTransactionList() {
      const data = {
        searcParam: this.searcParam
      };
      this.$vs.loading();

      await this.fetchTransactionListWithFilter(data.searcParam).then((result) => {
        this.transactions = result.data.data.docs;
        this.searcParam.totalData = result.data.data.pagination.total ? result.data.data.pagination.total : 0;
        this.$vs.loading.close();
      }).catch((ex) => {
        this.$vs.loading.close();
      });
    },
    moneyFormat(data) {
      return `$ ${parseFloat(data).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
    },
    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },
    viewDetail(data) {
      this.$router.push({
        name: "staff-payment-requests-detail",
        params: { id: data.paymentRequest._id },
      });
    },
    changeFilter(value) {
      this.searcParam.sortBy = value;
      this.searcParam.sortDir = this.searcParam.sortDir === "desc" ? "asc" : "desc";
    },

    changeStatus(event) {
      let showArray = this.statusType;
    },
    onChangeInput() {},

    changeStatusColor(status) {
      let bgClass = "";
      if (status == "Expired" || status == "Cancelled") {
        bgClass = "badge danger";
      } else if (status == "Refunded") {
        // yellow
        bgClass = "badge warning";
      } else if (status == "Completed") {
        bgClass = "badge success";
      } else {
        bgClass = "badge primary";
      }
      return bgClass;
    },

    toggleFilter() {
      this.filtersHidden = !this.filtersHidden;
      if (this.filtersHidden === true) {
        this.searcParam = {
          pageNumber: 1,
          totalData: 0,
          limit: 50,
          date: "",
          reference: "",
          customer: "",
          transaction: "",
          amount: "",
          statusType: [""],
          pageList: ["all"],
          sortBy: "createdAt",
          sortDir: "desc",
          merchantId: "",
          paymentRequestId: "",
        };
      }
    },

    removeShowAll() {
      for (let i = 0; i < this.searcParam.statusType.length; i++) {
        if (this.searcParam.statusType[i] === "all") {
          this.searcParam.statusType.splice(i, 1);
        }
      }
    },

    resetInput(event, id) {
      if (id == "date") {
        this.searcParam.date = "";
      } else if (id == "amount") {
        this.searcParam.amount = "";
      } else if (id == "customer") {
        this.searcParam.customer = "";
      } else if (id == "transaction") {
        this.searcParam.transaction = "";
      } else if (id == "merchantId") {
        this.searcParam.merchantId = "";
      } else if (id == "paymentRequestId") {
        this.searcParam.paymentRequestId = "";
      }
    },
  },
  mounted() {
    this.getListSetting();
    this.getTransactionList();
  },
  watch: {
    selectedPage(val) {
      this.$emit("handlePage", val);
    },
    "searcParam.statusType"(val) {
      this.getTransactionList();
    },

    "searcParam.reference"(val) {
      this.getTransactionList();
    },

    "searcParam.customer"(val) {
      this.getTransactionList();
    },

    "searcParam.transaction": _.debounce(function (val) {
      this.getTransactionList();
    }, 600),

    "searcParam.amount": _.debounce(function (val) {
      this.getTransactionList();
    }, 600),

    "searcParam.date"(val) {
      this.getTransactionList();
    },

    "searcParam.pageNumber"(val) {
      this.getTransactionList();
    },

    "searcParam.paymentRequestId": _.debounce(function (val) {
      this.getTransactionList();
    }, 600),

    "searcParam.merchantId": _.debounce(function (val) {
      this.getTransactionList();
    }, 600),

    "searcParam.limit"(val) {
      this.getTransactionList();
      this.saveListSetting(val);
    },
    "searcParam.pageList"(val) {
      this.getTransactionList();
    },
    "searcParam.sortDir"(val) {
      this.getTransactionList();
    },
    "searcParam.sortBy"(val) {
      this.getTransactionList();
    },
  },
  computed: {
    filterIconColor() {
      if (!this.filtersHidden) {
        return { fill: "#0f67f4", stroke: "#0f67f4" };
      }

      return { fill: "white", stroke: '#828282' };
    },
    user() {
      return this.$store.state.AppActiveUser;
    },
    partnerId() {
      return ["admin","superadmin"].includes(this.user.userType.toLowerCase()) ? this.user._id : this.user.partnerId;
    },
    totalPage: function () {
      return Math.ceil(this.searcParam.totalData / this.searcParam.limit);
    },
  },
};
</script>

